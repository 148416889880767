.viewport {
    background-color: rgb(241, 245, 249);
  }
  
  .node {
    width: 180px;
    background-color: #ffffff;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 12px 15px;
  }
  
  .node :global .react-flow__handle {
    visibility: hidden;
  }
  
  .label {
    font-family: monospace;
    font-size: 12px;
    text-align: center;
  }
  
  .button {
    font-size: 9px;
    font-family: monospace;
    position: absolute;
    bottom: -20px;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100px;
    text-align: center;
    color: rgb(148, 163, 184);
    background-color: rgba(241, 245, 249, 0.5);
  }
  
  .button:hover {
    color: rgb(71, 85, 105);
  }
  