.cunstom_node__container {
  background-color: white;
  border-radius: 1rem;
  border: 1px solid #e5e7eb;
  box-shadow: 0 0 transparent, 0 0 transparent, 0 7px 9px 0 rgba(0, 0, 0, 0.02);
  cursor: pointer;
  &__title {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: 0.6rem;
    padding-top: 0.6rem;
    padding-left: 0.8rem;
    padding-right: 1.6rem;
    border-bottom: 1px solid #e5e7eb;
    .flex_between {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: center;
      min-width: 227px;
      min-height: 20px;
      .nodeName {
        
        line-height: 1rem;
        color: #0f172a;
        margin: 0;
        font-weight: 600;
        span:first-child {
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-width: 123px;
          display: inline-block;
          display: inline-block;
          vertical-align: middle;
          font-size: 1rem;
          font-weight: 600;
        }
        span:last-child {
          vertical-align: middle;
          font-weight: 500;
          font-size: 0.9rem;
        }
      }
      .editNameButton {
        background-color: transparent;
        border: none;
        font-size: 0.75rem;
        position: absolute;
        top: 14px;
        right: 10px;
        cursor: pointer;
      }
    }
    .placeholder {
      div {
        background: #eee;
        width: 200px;
        height: 15px;
        margin-bottom: 4px;
      }
    }

    .placeholder div:last-child {
      margin-bottom: 0;
    }

    .input_container {
      display: flex;
      flex-direction: column;
      position: relative;

      input {
        font-size: 1rem;
        padding-left: calc(0.5rem - 3px);
        background: transparent;
        border: none;
        border-bottom: 1px solid gray;
        color: #0f172a;
        height: 20px;
        flex-grow: 1;
        padding-right: 12px; /* Make room for the icon */
      }
      input:focus {
        outline: none;
      }
      .icon_button {
        position: absolute;
        right: -15px;
        top: 5px;
        border: none;
        background: transparent;
        padding: 0;
        cursor: pointer;
      }
    }
  }

  .network_info {
    display: grid;
    grid-template-columns: auto auto;
    gap: 10px;
    font-family: sans-serif;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
    padding-left: 0.75rem;
    padding-right: 0.75rem;
    .row {
      display: contents;
      font-size: 0.75rem;

      .label {
        grid-column: 1;
        font-weight: bold;
        text-align: right;
        line-height: 1rem;
        color: #808080;
      }

      .value {
        display: inline-flex;
        align-items: center;
        grid-column: 2;
        text-align: left;

        svg {
          margin-right: 4px;
          margin-left: 4px;
        }
      }
    }
  }
}

.expand {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 9px;
  margin-bottom: 10px;
  .addButton {
    width: 25px; /* Width of the button */
    height: 25px; /* Height of the button */
    border-radius: 50%; /* Makes it circular */
    display: flex; /* Enables flexbox */
    justify-content: center; /* Centers content horizontally */
    align-items: center; /* Centers content vertically */
    border: 1px solid #000; /* Adds a border (optional) */
    font-size: 22px; /* Size of the minus sign */
    line-height: 1; /* Adjust line height to ensure the minus is centered */
    background-color: #fff; /* Background color of the button */
    color: #000; /* Color of the minus sign */
    cursor: pointer; /* Changes cursor to pointer on hover */
  }

  .addButton:hover {
    transform: scale(1.1); /* Scales up the button */
    background-color: #f2f2f2; /* Changes background color on hover */
  }

  .addButton:focus {
    outline: none; /* Removes focus outline */
  }

  .addButton:active {
    background-color: #eee; /* Background color on click */
  }
}
