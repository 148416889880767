.controlPanel {
  display: flex;
  flex-direction: column;
  background: #eef0f2;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  width: 300px;
  min-height: 40px;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 99;
  user-select: none;
  font-size: 12px;

  .collapseBar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 5px 10px;
    cursor: pointer;
    width: 15px;
    height: 24px;
    :hover {
      color: gray;
    }
    /* Style to indicate the bar is clickable, maybe change background on hover, etc. */
  }
  .controlContent {
    overflow: hidden;
    transition: max-height 0.5s ease-in;
    background-color: #dadee1;
    border-radius: 9px;
    padding-top: 10px;
  }

  .collapsedContent {
    max-height: 0;
  }

  .handle {
    cursor: move; /* Optional: gives a visual cue that the element is draggable */
  }

  .cancelDragging {
    /* Specific styles if needed */
  }
  .flexAround{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 5px;
    cursor: move;
  }
  .inputsContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    color: #535760;
    font-weight: 600;
    font-family: 'monospace';
    .row {
      display: flex;
      flex-direction: row;
      justify-content: space-around;
      align-items: center;
      width: 100%;
      margin-bottom: 5px;
      .label {
        width: 30%;
      }

      .value {
        width: 60%;
        .inputSubnet {
          width: -webkit-fill-available;
          border: 1px solid #ddd;
          border-radius: 4px;
          padding: 6px;
        }
        .slicingContainer {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          width: 100%;
          .inputSlicing {
            background: #ddd;
          }
          .slicingValue {
            margin-top: 4px;
            text-align: center;
            width: 15%;
            border: 1px solid #ddd;
            border-radius: 4px;
            padding: 6px;
          }
        }
      }
    }
  }
}
.buttonGroup {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 1em;
  .submitButton {
    padding: 3px 12px;
    background-color: #535760;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;

    &:hover {
      background-color: white;
      color: #535760;
    }
  }
}

// input[type="range"] {
//   /* removing default appearance */
//   -webkit-appearance: none;
//   appearance: none;
//   /* creating a custom design */
//   width: 100%;
//   cursor: pointer;
//   outline: none;
//   height: 30px;
//   width: 15px;  /*  overflow: hidden;  remove this line*/

//   /* New additions */
//   height: 6px;
//   background: #ccc;
// }

// /* Thumb: webkit */
// input[type="range"]::-webkit-slider-thumb {
//   /* removing default appearance */
//   -webkit-appearance: none;
//   appearance: none;
//   /* creating a custom design */
//   height: 30px;
//   width: 15px;
//   background-color: #f50;
//   border: none;

//   /* box-shadow: -407px 0 0 400px #f50; emove this line */
//   transition: .2s ease-in-out;
// }

// /* Thumb: Firefox */
// input[type="range"]::-moz-range-thumb {
//   height: 30px;
//   width: 15px;
//   background-color: #f50;

//   border: none;

//   /* box-shadow: -407px 0 0 400px #f50; emove this line */
//   transition: .2s ease-in-out;
// }

// /* Hover, active & focus Thumb: Webkit */

// input[type="range"]::-webkit-slider-thumb:hover {
//   box-shadow: 0 0 0 10px rgba(255,85,0, .1)
// }
// input[type="range"]:active::-webkit-slider-thumb {
//   box-shadow: 0 0 0 13px rgba(255,85,0, .2)
// }
// input[type="range"]:focus::-webkit-slider-thumb {
//   box-shadow: 0 0 0 13px rgba(255,85,0, .2)
// }

// /* Hover, active & focus Thumb: Firfox */

// input[type="range"]::-moz-range-thumb:hover {
//   box-shadow: 0 0 0 10px rgba(255,85,0, .1)
// }
// input[type="range"]:active::-moz-range-thumb {
//   box-shadow: 0 0 0 13px rgba(255,85,0, .2)
// }
// input[type="range"]:focus::-moz-range-thumb {
//   box-shadow: 0 0 0 13px rgba(255,85,0, .2)
// }
